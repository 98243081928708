@font-face {
	font-family: "Poppins-Regular";
	font-weight: 400;
	src: url("./Poppins-Regular.ttf");
}

@font-face {
	font-family: "Poppins-Bold";
	font-style: bold;
	font-weight: 700;
	src: url("./Poppins-Bold.ttf");
}
