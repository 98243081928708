@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";

@import "assets/assets";
@import "App";

@import "components/navbar";
@import "components/storeButton";

@import "pages/Home";
