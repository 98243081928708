@import "../variables";

.logo-container {
	cursor: pointer;
	.logo {
		height: 24px;
		width: auto;
	}
}

.nav-clickable {
	cursor: pointer;
	overflow: hidden;
	position: relative;
	display: inline-block;
	font-size: 0.833rem;
	padding: unset;
	padding: 0;
	margin: 0 15px;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		left: 0;
		background-color: $color-main;
		height: 2px;
		bottom: 0;
		transform-origin: 100% 50%;
		transform: scaleX(0);
		transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
	}
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		left: 0;
		content: attr(data-replace);
		height: 100%;
		top: 0;
		transform-origin: 100% 50%;
		transform: translate3d(200%, 0, 0);
		transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
		color: $color-main;
	}
	&:hover {
		&::before {
			transform-origin: 0% 50%;
			transform: scaleX(1);
		}
		&::after {
			transform: translate3d(0, 0, 0);
		}
		span {
			transform: translate3d(-200%, 0, 0);
		}
	}
	span {
		font-size: 0.833rem;
		display: inline-block;
		transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
	}
}
