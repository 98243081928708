$font-main: "Poppins-Regular";
$font-bold: "Poppins-Bold";

$color-text: #ffffff;
$color-text-muted: #676767;
$color-text-alternate: #222222;

$color-main: #2ab24c;
$color-secondary: #7fd194;

$background-main: #1d1d1d;
$background-card: #3b3737;

$size_h1: 1.802rem;
$size_h2: 1.602rem;
$size_h3: 1.424rem;
$size_h4: 1.266rem;
$size_h5: 1.125rem;
$size_h6: 1rem;
$size_small: 0.833rem;

/** Pico **/
$primary-500: #2ab24c;
$primary-600: #31a84f;
$primary-700: #389d52;
