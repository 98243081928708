@import "variables";

html {
	font-size: 100%;
	background: $background-main;
} /*16px*/

body {
	background: $background-main;
	font-family: $font-main;
	font-weight: 400;
	line-height: 1.75;
	color: $color-text;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: $font-bold;
	font-weight: 400;
	line-height: 1.3;
}

h1 {
	font-size: $size_h1;
}

h2 {
	font-size: $size_h2;
}

h3 {
	font-size: $size_h3;
}

h4 {
	font-size: $size_h4;
}

h5 {
	font-size: $size_h5;
}

h6 {
	font-size: $size_h6;
}

small,
.text_small {
	font-size: $size_small;
}

p {
	letter-spacing: 0.1em;
}

.text-primary {
	color: $color-main;
}

.bg-main {
	background-color: $background-main;
}

.bg-card {
	background-color: $background-card;
}

.bg-primary {
	background-color: $color-main;
}

.hide-mobile {
	display: none;
	@media only screen and (min-width: 992px) {
		display: flex;
	}
}

.hide-mobile-block {
	display: none;
	@media only screen and (min-width: 992px) {
		display: block;
	}
}

.show-only-mobile {
	display: flex;
	@media only screen and (min-width: 992px) {
		display: none;
	}
}

.debug {
	border: 1px solid red;

	&.blue {
		border-color: blue;
	}
	&.green {
		border-color: green;
	}
}
