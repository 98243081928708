@import "../variables";

.home-container {
	position: relative;
	z-index: 1;
	.service-hero {
		padding: 20 0 40px;
		height: 100%;
		display: flex;
		flex-direction: column;

		@media only screen and (min-width: 992px) {
			padding: 30% 0 40px;
		}
		// justify-content: center;
		.store-buttons {
			display: flex;
			flex-direction: row;
		}
	}
	.image-side {
		padding: 0 10px 0 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		// position: fixed;

		&.mobile {
			padding: unset;
			margin-bottom: 10px;
			img {
				height: 40vh;
				width: auto;
			}
		}
	}
}

.muted {
	color: $color-text-muted;
}

.cut-background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000000;
	width: 39vw;
}
