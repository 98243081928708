.store-button {
	display: flex;
	padding: 12px 24px;
	background: #000;
	border-radius: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		transform: scale(1.05);
	}

	.icon {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
	// white-space: nowrap;
}
